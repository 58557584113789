import React from "react";
import SEO from "../components/seo";
import { Link } from "gatsby";
import { Container } from "../components/container";

function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen glamour-background">
      <SEO title="404: Not found" description="Page not found" />
      <Container className="p-8 bg-white">
        <h2 className="text-3xl rounded font-bold my-8">
          This page does not exist
        </h2>
        <button className="shadow rounded-md">
          <Link
            to="/"
            className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-gray-800 border border-transparent leading-6 rounded-md hover:bg-gray-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
          >
            Home
          </Link>
        </button>
      </Container>
    </div>
  );
}

export default NotFoundPage;
